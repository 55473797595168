import { GlobalPairingRoundDetailsLayout } from '../components/Pairing/GlobalRound';

function GlobalPairingRoundDetailsRoute() {
  return <GlobalPairingRoundDetailsLayout />;
}

export const Component = GlobalPairingRoundDetailsRoute;

export function clientLoader() {
  return null;
}
